import { EventBus } from '@/event-bus'
import zwsCommandBuilder from '@/services/zws-command-builder'

export const mapSimulationMixin = {
  props: {
    map: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    changedFeatures: [],
    relatedFeaturesLoading: false
  }),
  methods: {
    addGroup (features) {
      const COLORS = {
        strokeColor: '#0a2ecaff',
        fillColor: '#0a2eca22'
      }
      EventBus.$emit('addMapGroup', features, COLORS)
    },
    async showRelatedFeatures (relation) {
      const relationPropertyName = relation.relationPropertyName.replace(/.*:/, '')
      const sourceFeatures = this.changedFeatures
        .filter(el => el.layer.id === relation.sourceLayer.id)
      if (sourceFeatures.length === 0) return EventBus.$emit('showInfoMessage', 'Нет подходящих объектов')
      this.relatedFeaturesLoading = true
      const relatedPropertyList = await zwsCommandBuilder.queryForListBySysIn(
        relation.sourceLayer,
        relationPropertyName,
        sourceFeatures.map(it => it.elemId)
      )
      const relatedFeaturesIds = relatedPropertyList
        .map(it => it[relationPropertyName] && it[relationPropertyName].value)
        .filter(el => el)

      if (relatedFeaturesIds.length === 0) {
        this.relatedFeaturesLoading = false
        return EventBus.$emit('showInfoMessage', 'Нет связанных объектов')
      }
      await zwsCommandBuilder.getLayerTypes(relation.targetLayer)
      await zwsCommandBuilder.getElemsByID(relation.targetLayer, relatedFeaturesIds, null, {
        excludeGeometry: false,
        excludeQueryList: true,
        excludeModeList: true,
        excludeProps: true
      })
        .then(features => EventBus.$emit('addMapGroup', features, {
          strokeColor: '#ff8133ff',
          fillColor: '#ff813322'
        }))
        .finally(() => this.relatedFeaturesLoading = false)
    }
  }
}
