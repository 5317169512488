<template>
  <vl-layer-vector :opacity="1" :visible="visible" :z-index="10">
    <vl-source-vector>
      <vl-feature
        v-for="feature in features"
        :properties="{
            obj: feature
          }"
      >
        <component
          :is="getComponent(feature.geometry.type)"
          :coordinates="feature.geometry.coordinates"
        />
        <vl-style-box>
          <vl-style-circle :radius="7">
            <vl-style-stroke
              :color="strokeColor"
              :width="4"
            />
          </vl-style-circle>
          <vl-style-stroke
            :color="strokeColor"
            :width="3"
          />
          <vl-style-fill
            :color="fillColor"
          />
        </vl-style-box>
      </vl-feature>
    </vl-source-vector>
  </vl-layer-vector>
</template>

<script>
export default {
  props: {
    features: Array,
    visible: Boolean,
    strokeColor: {
      type: String,
      default: '#9900ff'
    },
    fillColor: {
      type: String,
      default: '#9900ff22'
    }
  },
  methods: {
    getComponent (geometryType) {
      switch (geometryType) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        case 'Polygon' :
          return 'vl-geom-polygon'
        case 'MultiPolygon' :
          return 'vl-geom-multi-polygon'
        default:
          console.error('Unsupported geometry type: ' + geometryType)
      }
    }
  }
}
</script>
